import * as types from "./constants";
import { JSEncrypt } from "jsencrypt";
import validator from "validator";
import moment from "moment";
import cookie from "js-cookie";
import config from "config/config";
import NotificationSound from 'assets/audio/alpha-alert.mp3';

export const darkBoxesIndices = [2, 3, 6, 7, 10, 11, 14, 15, 18, 19, 22, 23];

export const months = ["January","February","March","April","May","June","July", "August","September","October","November","December"];

export const isSuperAdmin = () => cookie.get(types.AppCookies.role) === "Super Admin";

export const getPrevMonth = () => {
  const prevMonth = months[new Date().getMonth() - 1];
  return prevMonth;
}

export const encryptPassword = (password: string) => {
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(types.LOGIN_ENC_PUB_KEY);
  var key = encrypt.encrypt(password).toString();

  return key;
};
//Parser
// const parser = new DOMParser()

export const validateEmail = (emailString: string) => {
  return validator.isEmail(emailString);
};

export const obejctivesToolTipMessages = {
  bothMissing : "This objective is missing answers for both A and B versions",
  aMissing: "This objective is missing answers for version A",
  bMissing: "This objective is missing answers for version B"
}

export const periodList = [
  { name: 1, value: "1st Hr" },
  { name: 2, value: "2nd Hr" },
  { name: 3, value: "3rd Hr" },
  { name: 4, value: "4th Hr" },
  { name: 5, value: "5th Hr" },
  { name: 6, value: "6th Hr" },
  { name: 7, value: "7th Hr" },
  { name: 8, value: "8th Hr" },
  { name: 9, value: "9th Hr" },
];

export const validateCommonFields = (
  firstName: string,
  lastName: string,
  email: string,
  password?: string,
  isPassword?: boolean
) => {
  const errors: any = {};
  if (firstName.trim().length === 0) {
    errors.firstName = "required";
  }
  if (lastName.trim().length === 0) {
    errors.lastName = "required";
  }
  if (email.trim().length === 0) {
    errors.email = "required";
  }
  if (email.trim().length > 0 && !validateEmail(email.trim())) {
    errors.email = "Invalid Email";
  }
  if (isPassword && password?.trim().length === 0) {
    errors.password = "required";
  }
  return errors;
};

export const validateSchoolFields = (
  schoolName: string,
  // addressOne: string,
  // addressTwo: string,
  // zip: string,
  // phone: string,
  // city: string,
  // state: string,
  stdLicense: string,
  defaultSummatives?: any[]
) => {
  const errors: any = {};
  if (schoolName.trim().length === 0) {
    errors.schoolName = "required";
  }
  // if (addressOne.trim().length === 0) {
  //   errors.addressOne = "required";
  // }
  // if (zip.trim().length === 0) {
  //   errors.zip = "required";
  // }
  // if (phone.trim().length === 0) {
  //   errors.phonr = "required";
  // }
  // if (city.trim().length === 0) {
  //   errors.city = "required";
  // }
  // if (state.trim().length === 0) {
  //   errors.state = "required";
  // }
  if (!stdLicense) {
    errors.stdLicense = "required";
  }
  if (Number(stdLicense) > 5000 || Number(stdLicense) < 0) {
    errors.stdLicense = "Licenses must be >= 0 && <= 5000";
  }

  console.log("defaultSummatives", defaultSummatives);

  let errorAssessment = defaultSummatives?.filter(
    (item: any) =>
      !moment(item?.start_date, "M-D-YY", true).isValid() ||
      (item.edit_end_date &&
        !moment(item?.edit_end_date, "M-D-YY", true).isValid()) ||
      (item.monitor_end_date &&
        !moment(item?.monitor_end_date, "M-D-YY", true).isValid())
  );
  if (errorAssessment?.length) {
    errors.assessment = "Fix Errors Before Saving";
  }

  return errors;
};

export const validateStudentFields = (
  firstName: string,
  lastName: string,
  schoolStdId: any
) => {
  const errors: any = {};
  if (firstName.trim().length === 0) {
    errors.firstName = "required";
  }
  if (lastName.trim().length === 0) {
    errors.lastName = "required";
  }
  if (schoolStdId.length > 10 || schoolStdId.length < 4) {
    errors.schoolStdId = "ID must be between 4-10 digits";
  }
  if (schoolStdId.trim().length === 0) {
    errors.schoolStdId = "required";
  }
  return errors;
};

export const validateStudentId = (schoolStdId: string) => {
  const errors: any = {};
  if (!schoolStdId.trim().match(/^\d+$/)) {
    errors.schoolStdId = "Student Id is Invalid";
  }
  if (schoolStdId.trim() === "") {
    errors.schoolStdId = "Student Id is required";
  }

  return errors;
};

export const validateCleverFields = (stdId: string, cleverId: string, update: boolean = false) => {
  const errors: any = {};
  if (stdId.trim().length === 0) {
    errors.stdId = "required";
  }
  if (!stdId.trim().match(/^\d+$/)) {
    errors.stdId = "Student Id is Invalid";
  }
  if (cleverId.trim().length === 0) {
    errors.cleverId = "required";
  }
  if (cleverId.trim().match(/\W/)) {
    errors.cleverId = "Clever ID is Invalid";
  }

  if (update && cleverId.trim().length < 22) {
    errors.cleverId = "Clever ID is Invalid";
  }

  return errors;
};

export const validateUpdateCleverFields = (stdId: string, cleverId: string, update: boolean = false) => {
  const errors: any = {};
  if (stdId.trim().length === 0) {
    errors.stdId = "required";
  }

  if (!stdId.trim().match(/^\d+$/)) {
    errors.stdId = "Student Id is Invalid";
  }

  if (cleverId && cleverId.trim().match(/\W/)) {
    errors.cleverId = "Clever ID is Invalid";
  }

  if (update && cleverId && cleverId.trim().length < 22) {
    errors.cleverId = "Clever ID is Invalid";
  }

  return errors;
};

export const validateClassFields = (
  selectedSubject: string,
  grade: string,
  currentAdmin: any,
  selectedPeriod: string
) => {
  const errors: any = {};
  if (selectedSubject === "0" || !selectedSubject) {
    errors.selectedSubject = "required";
  }
  if (grade === "0" || !grade) {
    errors.grade = "required";
  }
  // if (selectedPeriod === "0" || !selectedPeriod) {
  //   errors.selectedPeriod = "required";
  // }
  if (currentAdmin === null) {
    errors.currentAdmin = "required";
  }
  return errors;
};

export const downloadPdfFile = (
  fileURL: string,
  fileName: string,
  file_type: string
) => {
  const extIsAvailable = fileName.endsWith(".pdf") || fileName.endsWith(".zip");
  const downloadFileName = extIsAvailable
    ? fileName.endsWith(".zip") && file_type === "ZIP"
      ? fileName
      : fileName.endsWith(".pdf") && file_type !== "ZIP"
        ? fileName
        : fileName + "." + (file_type === "ZIP" ? "zip" : "pdf")
    : fileName + "." + (file_type === "ZIP" ? "zip" : "pdf");
  fetch(fileURL, {
    method: "GET",
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadFileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();
    });
};

export const formatName = (name: string) => {
  const tokenizedName = name.split(" ");
  const lastName = tokenizedName[tokenizedName.length - 1];
  tokenizedName.pop();
  return `${lastName}, ${tokenizedName.join(" ")}`;
};

export const validateURL = (URL: string) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(URL);
};

export const ordinal_suffix_of = (i: number) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

function detectBrowser() {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else {
    return "Unknown";
  }
}

function detectOS() {
  if (navigator.userAgent.indexOf("Windows") !== -1) {
    return "Windows";
  } else if (navigator.userAgent.indexOf("Mac") !== -1) {
    return "Mac";
  } else if (navigator.userAgent.indexOf("X11") !== -1) {
    return "X11";
  } else if (navigator.userAgent.indexOf("Linux") !== -1) {
    return "Linux";
  } else {
    return "Unknown";
  }
}

export const isSafari = detectBrowser() === "Safari";
export const isChrome = detectBrowser() === "Chrome";
export const isFirefox = detectBrowser() === "Firefox";

const detectBrowserHeights = () => {
  let browserHeights = 'chromeHeights'
  if (isFirefox) browserHeights = 'firefoxHeights'
  else if (isSafari) browserHeights = 'safariHeights'

  return browserHeights
}

export const browserHeights = detectBrowserHeights()

// Detect OS
export const isWindows = detectOS() === "Windows";
export const isMac = detectOS() === "Mac";
export const isLinux = detectOS() === "Linux";

export const handlePress = (event: any) => {
  const isOtherKey = localStorage.getItem('isOtherKey')
  if (
    event.keyCode &&
    ((isMac && event.metaKey === false) || ((isWindows || isLinux) && event.ctrlKey === false)) && event.keyCode !== 13
  ) {
    localStorage.setItem('isOtherKey', 'true')
  }
  if (
    (event.key === "Enter" && event.ctrlKey === true && !isOtherKey) ||
    (isMac && event.metaKey === true && event.key === "Enter") ||
    ((isWindows || isLinux) &&
      event.ctrlKey === true &&
      event.key === "Enter")
  ) {
    localStorage.removeItem('isOtherKey')
    document.getElementById("save-button")?.click();
  }
}

export const handleKeyUp = (event: any) => {
  event.key !== "Enter" && localStorage.removeItem('isOtherKey')
}

export const handlePressClass = (event: any) => {
  if ((isMac && event.metaKey === true && event.key === "Enter") ||
    ((isWindows || isLinux) &&
      event.ctrlKey === true &&
      event.key === "Enter")) {
    document.getElementById("save-button")?.click();
  }
};


export const clearAllData = async (callBack?: Function) => {
  cookie.remove(types.AppCookies.showSurvey);
  cookie.remove(types.AppCookies.email);
  cookie.remove(types.AppCookies.user);
  cookie.remove(types.AppCookies.role);
  cookie.remove(types.AppCookies.token);
  cookie.remove(types.AppCookies.password);
  cookie.remove(types.AppCookies.first_name);
  cookie.remove(types.AppCookies.last_name);
  cookie.remove(types.AppCookies.has_kindergarten);
  cookie.remove(types.AppCookies.has_zero_students_classrooms);
  console.log('removing', config.defaults.discussions_url);
  cookie.remove(types.AppCookies.token, { domain: `${config.defaults.discussions_url}` });
  cookie.remove(types.AppCookies.token, { domain: `.aplustesting.org` });
  localStorage.removeItem("survey_answers");
  localStorage.removeItem("current_question");
  localStorage.removeItem("type")
  clearSessions(() => {
    callBack && callBack();
  });
  
};

export const clearSessions = (callBack?: Function) => {
  sessionStorage.removeItem("district_id");
  sessionStorage.removeItem("school_id");
  sessionStorage.removeItem("teacher_id");
  sessionStorage.removeItem("grade_id");
  sessionStorage.removeItem("subject_id");
  sessionStorage.removeItem("classroom_id");
  sessionStorage.removeItem("c_school_id");
  sessionStorage.removeItem("c_grade_id");
  sessionStorage.removeItem("c_subject_id");
  sessionStorage.removeItem("c_classroom_id");
  sessionStorage.removeItem("c_district_id");
  sessionStorage.removeItem("district_m_id");
  sessionStorage.removeItem("school_m_id");
  sessionStorage.removeItem("classroom_m_id");
  localStorage.removeItem("stlLoading");
  localStorage.removeItem("stlSingleLoading");
  callBack && callBack();
};

// export const checkIfNumber = (event: any) => {
//   // Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys.

//   const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
  
//   return !event.key.match(regex) && event.preventDefault();
// }



export const checkIfNumber = (event: any) => {
  
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
  if ((event.ctrlKey || event.metaKey) && ['c', 'v', 'x', 'a'].includes(event.key.toLowerCase())) {
    return true;
  }

  const isNumber = /^\d$/.test(event.key);
  const isAllowedKey = allowedKeys.includes(event.key);
  
  if (isNumber || isAllowedKey) {
    return true;
  }
  
  // Prevent default if the key is not allowed.
  event.preventDefault();
};

export const handlePaste = (event: any) => {
  const pastedData = event.clipboardData.getData('text');
  
  // Prevent pasting if the clipboard data is not entirely numeric.
  if (!/^\d+$/.test(pastedData)) {
    event.preventDefault();
  }
};







let isNotified = false;

export const playSound = (uniqueId: number) => {
  if(!uniqueId){
    return;
  }
  
  const newAudio = new Audio(NotificationSound);
  if(!isNotified){
    isNotified = true;
    const isCurrentNotification = Number(localStorage.getItem('notification_id')) === uniqueId;
    if(!isCurrentNotification){
      localStorage.setItem("notification_id", `${uniqueId}`)
      newAudio?.play();

      setTimeout(() => {
        localStorage.removeItem("notification_id");
        isNotified = false;
      },5000)
    }
  }
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPerformanceLevels = (level: any[]) => {
  if(!Array.isArray(level)) return {};
  
  const advanceLevel = level?.length > 0 ? level[0].percentage : 0;
  const proficientLevel = level?.length > 1 ? level[1].percentage : 0;
  const basicLevel = level?.length > 2 ? level[2].percentage : 0;
  const belowBasicLevel = level?.length > 0 ? level[3].percentage : 0;

  return {
    advanceLevel,
    proficientLevel,
    basicLevel,
    belowBasicLevel
  }
}

export const saveFormative = (open: boolean, formative:any) => {
  open && sessionStorage.setItem("open_formative", formative.id);
}

export const getReportFieldName = (pathname: string) => {
  let field;
  switch(true){
    case pathname.includes("report/master-report"):
    case pathname.includes("report/item-response-report-summative"):
    case pathname.includes("report/summative"):
    case pathname.includes("report/standard-at-risk"):
      field = ['has_summative_a1', 'has_summative_a2', 'has_summative_b']
      break
    case pathname.includes("report/oag-report"):
    case pathname.includes("report/item-response-report-formative"):
      field = 'has_formative_reports'
      break
    default:
      field = ""
      break
  }

  return field;
}

export const getFormattedTime = (time: number) => {
  if(!time) return ""

  let formattedTime;
  switch(true){
    case time > 1440:
      formattedTime = `${Math.floor(time/1440)}d`
      break
    case time > 60:
      formattedTime = `${(time/60).toFixed(1)}h`
      break
    default:
      formattedTime = `${time}m`
  }

  return formattedTime;
}

export const getLegendPadding = (classrooms: any) => {
  if(!classrooms?.length) return 5;
  
  const class_rooms = classrooms;

      let total_tests=[];
      let proficiency=[];
      let maxProfIndex = 0;
      let maxTotalIndex = 0;

      for(let j=0; j<class_rooms.length; j++){
        proficiency.push(class_rooms[j].formative_test_data.proficiency);
        total_tests.push(class_rooms[j].formative_test_data.total_tests_taken);
        if(class_rooms[j].formative_test_data.proficiency > class_rooms[maxProfIndex].formative_test_data.proficiency){
          maxProfIndex = j;
        }

        if(class_rooms[j].formative_test_data.total_tests_taken > class_rooms[maxTotalIndex].formative_test_data.total_tests_taken){
          maxTotalIndex = j;
        }
      }

      let maxProfCoordinate = class_rooms[maxProfIndex].formative_test_data.proficiency
      while(maxProfCoordinate%10) maxProfCoordinate++

      let maxTestCoordinate = class_rooms[maxTotalIndex].formative_test_data.total_tests_taken
      while(maxTestCoordinate%5) maxTestCoordinate++

      let i=2;
      let legendPadding = 5;
      while(i<proficiency.length-2){
        if(maxProfCoordinate - proficiency[i] < 4){
          legendPadding = 20;
        }
        if(maxTestCoordinate === total_tests[i] && total_tests[i]%5 === 0){
          legendPadding = 30;
          break;
        }
        if(maxTestCoordinate - total_tests[i] < 3){
          legendPadding = 17;
        }
        i++;
      }
      
      return legendPadding;
      
}