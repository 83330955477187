import { useEffect, useState } from "react"

export default function useWindowScrollPosition(sessionStorageKey: string, enableScroll: boolean) {

    const [scrollValue, setScrollValue] = useState(0)

    const handleScroll = () => {
        setScrollValue(window.pageYOffset)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        console.log(enableScroll, "enable");
        const scrollValue = sessionStorage.getItem(sessionStorageKey)
        if (enableScroll && scrollValue) {
            window.scrollTo(0, parseInt(scrollValue))
            sessionStorage.removeItem(sessionStorageKey)
        }
    }, [enableScroll, sessionStorageKey])

    return scrollValue
}