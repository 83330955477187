import React, { FC } from "react";
import ToolTip from "./Tooltip";
import { VARIANT_TOOLTIP_CONTENT } from "util/constants";
interface Props {
  attemptVariant: string;
  givePadding?: boolean;
  isPerformance?: boolean;
}

const AttemptVariant: FC<Props> = ({
  attemptVariant,
  givePadding,
  isPerformance,
}) => {
  const atmptVrnt = !isPerformance ? attemptVariant.split("-") : attemptVariant;
  return !isPerformance ? (
    <div
      style={{ cursor: "pointer" }}
      className={`d-flex justify-content-end align-items-center variant-chip-container ${
        givePadding ? "pe-3" : ""
      }`}
    >
      <span className="variantNumber">{atmptVrnt[0]}{" "}</span>
      <ToolTip
        styles={{ width: "500px", textAlign:"left", }}
        left={-220}
        top={-90}
        content={<p style={{margin:"4px"}}>{VARIANT_TOOLTIP_CONTENT}</p>}
      >
        <span className="variant">Ver {atmptVrnt[1]}</span>
      </ToolTip>
    </div>
  ) : (
    <ToolTip
      styles={{ width: "500px",  textAlign:"left"}}
      left={-220}
      top={-90}
      content={<p style={{margin:"4px"}}>{VARIANT_TOOLTIP_CONTENT}</p>}
      
    >
      <span className="variant">Ver {atmptVrnt}</span>
    </ToolTip>
  );
};

export default AttemptVariant;
