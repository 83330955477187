export  const LOGIN_ENC_PUB_KEY= '-----BEGIN PUBLIC KEY-----\n' +
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn+H60UfXEWsesCbOs03A\n' +
    'TBS2mqvMcJD0+dmPSiyxORic2cjj3Gplaih0A5MZN8b40+8bQP4SxJPe/bAw2ssc\n' +
    'djE2DchpRe30WPHODyASW+/VoSJQvQOqSqP39LTTEdhxQVvV54KHPYfWnsRRaHP0\n' +
    'XOnmWjnc5Z/d6pcZwIsMpI1hFnie3AEgeDigrctP3OH8qVkSKc5Vq8dRQc1wsLME\n' +
    'GSlo74mWaHpkGOo8EWsTttkKUZI8pB8mbhi4+o/YT9v5MUF31+5EVtogkDc9kiWq\n' +
    'GdzuF199v5UIpqTmTxWy8z4oj7EcKt/rH8zgBMEVqJjrYb5L/YFvV8icYc0wEPJG\n' +
    'cQIDAQAB\n' +
    '-----END PUBLIC KEY-----';

export const SCROLL_VALUE_FORMATIVE = 'SCROLL_VALUE_FORMATIVE';
export const SCROLL_VALUE_SUMMATIVE = 'SCROLL_VALUE_SUMMATIVE';
export const SCROLL_VALUE_MANAGEMENT = 'SCROLL_VALUE_MANAGEMENT';

export const GOALS_CONDITIONS = {
    Open_SBR_and_Click_Print_All: 'Open SBR and Click Print All',
    Open_Master_Report: 'Open Master Report',
    Open_Classroom_Summative_Report: 'Open Classroom Summative Report',
    Open_Pacing_Calendar: 'Open Pacing Calendar',
    Generate_DTM_Worksheet: 'Generate DTM Worksheet',
    SBR_Print_All_English: 'SBR Print All English',
    SBR_Print_All_Spanish: 'SBR Print All Spanish',
    Open_Standards_at_Risk: 'Open Standards at Risk',
    Download_One_Time_File: 'Download One-Time File',
    Open_W2A_Report: 'Open W2A Report',
    Open_Formative_IRR: 'Open Formative IRR'
}

export const FORMATIVE_PROFICIENY = 80;
export const TOOLS_LIMIT = 6;

export const DEFAULT_PROGRESS_DATA = [1,2,3,4,5];

const getCookieNameWithSuffix = (name: string) => {
    const environment = process.env.REACT_APP_ENV
    
    return name + "_" + environment;
}

export const AppCookies:any = {
    token: getCookieNameWithSuffix("super-admin-token"),
    email: getCookieNameWithSuffix("email"),
    role: getCookieNameWithSuffix("role"),
    forgot_password: getCookieNameWithSuffix("forgot_password"),
    first_name: getCookieNameWithSuffix("first_name"),
    last_name: getCookieNameWithSuffix("last_name"),
    user: getCookieNameWithSuffix("user"),
    has_kindergarten: getCookieNameWithSuffix("has_kindergarten"),
    has_zero_students_classrooms: getCookieNameWithSuffix("has_zero_students_classrooms"),
    profile_color_code: getCookieNameWithSuffix("profile_color_code"),
    support_pin: getCookieNameWithSuffix("support_pin"),
    grade_id: getCookieNameWithSuffix("grade_id"),
    subject_id: getCookieNameWithSuffix("subject_id"),
    assessmentName: getCookieNameWithSuffix("assessmentName"),
    test_name: getCookieNameWithSuffix("test_name"),
    showSurvey: getCookieNameWithSuffix("showSurvey"),
    isMath: getCookieNameWithSuffix("isMath"),
}

export const VARIANT_TOOLTIP_CONTENT = "Formative assessments alternate between two versions (A and B). Students start with Version A, are assigned Version B after remediation and then return to Version A on the third attempt.";