import { ProgressbarCore } from "components/theme/activityBar/progressBar";
import NumberedLabel from "./numberedLabel";
import { AppCookies, FORMATIVE_PROFICIENY, SCROLL_VALUE_SUMMATIVE } from "util/constants";
import { useDispatch } from "react-redux";
import { useContext, useState } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import moment from "moment";
import ActionType from "redux/actionTypes";
import Cookies from "js-cookie";
import Guide from "components/common/svgs/guide";
import { Assessment, ReportsNavigation } from "interfaces/professionalDevelopment";
import AvailabilityModal from "components/classroom/modals/availability";
import ReportClickAlertModal from "components/classroom/modals/ReportClickAlertModal";
import PreviewHelpModel from "components/classroom/modals/previewHelpModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core";
import Skeleton from "react-loading-skeleton";
import { saveFormative } from "util/index";
import PersonIcon from "components/common/svgs/person-icon";
import Toggle from "components/common/Toggle";

library.add(faCaretLeft);

const Formative = ({ formative, scrollValue, onClickRemediate, handleOnChangeToggleFormative, formativeLoading, open }: any) => {
    const {
        id,
        proficiency,
        students_assessed_count,
        students_total_count,
        remediation_count,
        enabled,
        available_students,
        name,
        last_activity,
        is_past_window,
        show_random_option
    } = formative;

    const lastActivityFormatted = last_activity ? moment(last_activity).format("M-D-YY") : 'Not Yet';
    const dispatch = useDispatch();
    const {
        navigateTo,
        isLoading: loading,
        classroomId
    } = useContext(ProfessionalDevelopmentContext);
    const isLoading = loading || formativeLoading;

    // const isScheduled = !!scheduled_date && scheduled_date !== "Not Scheduled"
    // const scheduledDay = scheduled_date ? moment(scheduled_date).format("dddd") : null;
    // const scheduledDate = scheduled_date ? moment(scheduled_date).format("MMM DD") : null;
    const enableAccess = !!students_assessed_count;


    const [showReviewHelpModel, setShowReviewHelpModel] = useState(false);
    const [helpTitle, setHelpTitle] = useState<any>(null);
    const [showReportAlertModal, setShowReportAlertModal] = useState(false);
    // const [toggle, setToggle] = useState(false);
    const [modalShow, setModalShow] = useState(false);


    const onClickAvailableStudents = () => {
        setModalShow(true);
        dispatch({
            type: ActionType.FORMATIVE_NAME,
            payload: name,
        });
    }

    const onChangeAvailable = (e: any) => {
        handleOnChangeToggleFormative(e, show_random_option)
    };

    return (
        <div className="w-100">
            <span className={`book-box report-row`}>
                <NumberedLabel
                    number={4}
                />
                <span className="formativeAssessment-name">Formative Assessment</span>
                <span className="last-activity">
                    <span className="label">Last Activity</span>
                    <span className="date-activity">
                        {isLoading ? (
                            <Skeleton baseColor="#00000033" highlightColor="#737373" width={90} height={18} borderRadius={20} style={{ marginTop: 1 }} />
                        ) : (
                            lastActivityFormatted
                        )}
                    </span>
                </span>
                <span className="progress-column">
                    <span className="label">Assessed</span>
                    {isLoading ? (
                        <Skeleton baseColor="#4788ff" highlightColor="#729bdd" borderRadius={8} width="80%" height={19} style={{ margin: "3px auto 0px" }} />
                    ) : (
                        <ProgressbarCore
                            width={Math.floor((students_assessed_count / students_total_count) * 100)}
                            isLoading={isLoading}
                        >
                            <p>{students_assessed_count} / {students_total_count}</p>
                        </ProgressbarCore>
                    )}
                </span>
                <span className="progress-column">
                    <span className="label">Proficiency</span>
                    {isLoading ? (
                        <Skeleton baseColor="#4788ff" highlightColor="#729bdd" borderRadius={8} width="80%" height={19} style={{ margin: "3px auto 0px" }} />
                    ) : (
                        <ProgressbarCore
                            width={proficiency}
                            color="#9080FF"
                            isDisabled={proficiency === null}
                            isProficient={proficiency >= FORMATIVE_PROFICIENY}
                            isLoading={isLoading}
                        >
                            <p>{proficiency}%</p>
                        </ProgressbarCore>
                    )}
                </span>
                <span 
                      role="button" 
                      tabIndex={0} 
                      onKeyDown={ (e:any) => { 
                          if(e.key === 'Enter') {
                            saveFormative(true, formative); 
                            navigateTo('formatives', ReportsNavigation.PREVIEW, formative)
                          }
                      } }  className="view-column">
                    <span className="label">View</span>
                    {isLoading ? (
                        <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width="29px" height={22} style={{ marginTop: "5px" }} />
                    ) : (
                        <>
                            <span className="cursor-pointer">
                                <Guide
                                    onClick={() => {
                                        saveFormative(true, formative); 
                                        sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
                                        navigateTo('formatives', ReportsNavigation.PREVIEW, formative)
                                            }}
                                        />
                                    </span>
                        </>
                    )}
                </span>
                <span
                    className="edit-column"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.stopPropagation();
                            if (isLoading) return;
                            saveFormative(true, formative); 
                            sessionStorage.setItem("FORMATIVE_NAME", name);
                            sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
                            navigateTo(Assessment.formative, ReportsNavigation.ANSWER, formative)
                        }
                    }}
                >
                    <span className="label">Add/Edit</span>
                    <svg
                        onClick={(e) => {
                            e.stopPropagation();
                            if (isLoading) return;

                            saveFormative(true, formative); 
                            sessionStorage.setItem("FORMATIVE_NAME", name);
                            sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
                            navigateTo(Assessment.formative, ReportsNavigation.ANSWER, formative)
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="23"
                        viewBox="0 0 20 23"
                        fill="none"
                        className={`${'cursor-pointer'}`}
                        style={{ opacity: isLoading ? 0.5 : 1 }}

                    >
                        <path d="M17.8946 1.75745C17.2805 1.20426 16.4718 0.91763 15.6464 0.960581C14.821 1.00353 14.0464 1.37255 13.4931 1.98648L1.79563 14.9681C1.71606 15.0555 1.66025 15.1619 1.63344 15.277L0.316116 21.2235C0.289478 21.3431 0.295099 21.4677 0.332402 21.5845C0.369704 21.7013 0.437335 21.8061 0.528409 21.8881C0.619485 21.9702 0.730737 22.0266 0.85076 22.0516C0.970783 22.0766 1.09528 22.0692 1.21153 22.0303L6.98509 20.1021C7.09683 20.0635 7.19686 19.9969 7.27557 19.9087L18.98 6.9278C19.5324 6.31334 19.8186 5.50483 19.7756 4.67967C19.7327 3.8545 19.3642 3.08007 18.7509 2.52626L17.8946 1.75745ZM3.3254 15.3372L12.8997 4.71391L16.3259 7.80114L6.75663 18.4262L3.3254 15.3372ZM2.74301 16.6767L5.47553 19.1389L1.93113 20.3251L2.74301 16.6767ZM17.9491 6.00078L17.254 6.77219L13.8268 3.68501L14.5229 2.91355C14.8304 2.57238 15.2607 2.3673 15.7193 2.34344C16.178 2.31958 16.6273 2.47888 16.9685 2.7863L17.8228 3.55521C18.1635 3.86292 18.3683 4.2932 18.3921 4.75166C18.416 5.21012 18.256 5.65938 17.9491 6.00078Z" fill="#323233" />
                    </svg>
                </span>
                <span className="report-name-column">
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            if (isLoading) return;

                            if (!enableAccess && !proficiency) {
                                setShowReportAlertModal(true)
                            } else {
                                Cookies.set(AppCookies.test_name, name);
                                saveFormative(true, formative);
                                navigateTo('formatives', ReportsNavigation.IRR, formative);
                                sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
                            }
                        }}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    if (isLoading) return;

                                    if (!enableAccess && !proficiency) {
                                        setShowReportAlertModal(true)
                                    } else {
                                        Cookies.set(AppCookies.test_name, name);
                                        saveFormative(true, formative);
                                        navigateTo('formatives', ReportsNavigation.IRR, formative);
                                        sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
                                    }
                                }
                            }}

                        style={{
                            cursor: 'pointer',
                            opacity:
                                isLoading || (!enableAccess && !proficiency)
                                    ? 0.26
                                    : 1,
                        }}
                        className="reportName"
                    >
                        IRR
                    </span>
                </span>
                <span onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                if (remediation_count !== 0 && !isLoading) {
                                    onClickRemediate(id);
                                }
                            }
                        }} role="button" tabIndex={0} className="remediate-column">
                    <span className="label">Remediate</span>
                    <span
                        className="reme-person"
                        
                        onClick={(e) => {
                            e.stopPropagation();
                            if (remediation_count !== 0 && !isLoading) {
                                onClickRemediate(id);
                            }
                        }}
                        style={{ cursor: remediation_count !== 0 ? 'pointer' : '', opacity: (remediation_count === 0 || isLoading) ? 0.26 : 1 }}
                    >
                        <PersonIcon width="17" height="24" />
                        {remediation_count}
                    </span>
                </span>
                <span className="available-students-column">
                    <span className="label">
                        Available
                    </span>
                    <span className="complete-toggle-container">
                        <Toggle 
                           disabled = {isLoading}
                           onClick={(e: any) => e.stopPropagation()} 
                           onChange={(e: any) => {
                            onChangeAvailable(e)
                        }}
                            checked={enabled || isLoading}
                            />
                    </span>

                    {enabled && (
                        <span
                            className={`many-students ${(enabled && !isLoading) ? 'cursor-pointer' : ''}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                enabled && !isLoading && onClickAvailableStudents()
                            }}
                        >
                            {isLoading ? (
                                <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={12} width="57%" height={14} style={{ margin: "3px auto 0" }} />
                            ) : (
                                <>{available_students} Students</>
                            )}
                        </span>
                    )}
                </span>
                {isLoading ? (
                    <td className="monitor-button monitor-button-skeleton">
                        <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width={88} height={33} />
                    </td>
                ) : (
                    <span className="monitor-button">
                        {enabled ? (
                            <button
                                type="button"
                                className={`button-green`}
                                tabIndex={0}


                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.stopPropagation();
                                        saveFormative(true, formative);
                                        navigateTo(Assessment.formative, ReportsNavigation.MONITOR, formative);
                                        sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);

                                    }
                                }}
                                id={`monitor-$${id}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    saveFormative(true, formative);
                                    navigateTo(Assessment.formative, ReportsNavigation.MONITOR, formative);
                                    sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
                                }}
                            >
                                Monitor
                            </button>
                        ) : (
                            <div className="turnMonitorContainer">
                                {!is_past_window && (
                                    <FontAwesomeIcon
                                        name="Check"
                                        icon={faCaretLeft}
                                        width={9}
                                        height={11}
                                        style={{ fontSize: 16, marginRight: '2px', marginTop: '-2px' }}
                                    />
                                )}
                                <div>
                                    <p className="turnMonitor" style={{ fontSize: 12 }}>
                                        Turn on to monitor
                                    </p>
                                </div>
                            </div>
                        )}
                    </span>
                )}
            </span>


            {modalShow === true && (
                <AvailabilityModal
                    show={modalShow}
                    summativeId={0}
                    formativeId={id}
                    setModalShow={setModalShow}
                    classroomId={classroomId}
                />
            )}



            {showReportAlertModal && (
                <ReportClickAlertModal
                    show={showReportAlertModal}
                    assessment="formative"
                    closeModal={() => {
                        // setO(0);
                        setHelpTitle(null);
                        setShowReportAlertModal(false);
                    }}
                    message={helpTitle}
                />
            )}

            {showReviewHelpModel && (
                <PreviewHelpModel
                    show={showReviewHelpModel}
                    closeModal={() => setShowReviewHelpModel(false)}
                />
            )}
        </div>
    )
};

export default Formative;
