/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */

import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { getSubjectList } from "services/get-subject-list";
import { getGradeList } from "services/get-grades-list";
import { getAllClassAdmins } from "services/get-admins";
import validator from "validator";
import moment from "moment";
import { getDefaultSummatives } from "services/get-default-summatives";
import {
  CustomInput,
  Input,
  SelectInput,
  ValidationHint,
} from "../common/inputComponent";
import { Button } from "../common/buttonComponent";
import {
  periodList,
  validateClassFields,
  validateCleverFields,
  validateCommonFields,
  validateEmail,
  validateSchoolFields,
  validateStudentFields,
  handlePress,
  handleKeyUp,
  handlePressClass,
  validateUpdateCleverFields,
  checkIfNumber,
  handlePaste
} from "../../util/index";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "redux/actionTypes";
import cookie from "js-cookie";
import { useDropzone } from "react-dropzone";
import { getDistrictLogoSelector } from "redux/actionCreators/management";
import { checkIfClassroomHasTests } from "redux/actionCreators/classroom";
import { AdminsSelectClass } from "components/common/selectComponent";
import Select from "react-select";
import CustomModal from "components/common/Modal";
import Toggle from "components/common/Toggle";
import { AppCookies } from "util/constants";

interface SuperAdminUpdateProps {
  isShow: boolean;
  first_name: string;
  last_name: string;
  input_email: string;
  input_title: string;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const SuperAdminUpdateModal: React.FC<SuperAdminUpdateProps> = ({
  isShow,
  loading,
  first_name,
  last_name,
  input_email,
  input_title,
  onCancel,
  onUpdate,
}) => {
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [email, setEmail] = useState(input_email);
  const [title, setTitle] = useState(input_title);
  const [isDisabled, setDisabled] = useState(true);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const { management } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (management.superAdminsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.superAdminsError,
      });
    }
  }, [management.superAdminsError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email, title]);

  const checkDisabled = () => {
    if (
      firstName &&
      lastName &&
      email &&
      (firstName !== first_name ||
        lastName !== last_name ||
        email !== input_email ||
        input_title !== title)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearData();
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        title: title?.trim(),
      };
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.UPDATE_SUPER_ADMIN_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Super Admin</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              autoFocus={true}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="email"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["email"] !== undefined}
              value={email}
              type="text"
              label="Email"
              onChange={handleOnChange}
              error={clientErrors.email}
              errorMessage={clientErrors.email}
              overlayClick={() => {
                setClientErrors({
                  ...clientErrors,
                  email:
                    clientErrors.email === "Invalid Email"
                      ? "required"
                      : undefined,
                });
              }}
            />
            <Input
              fieldName="title"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["title"] !== undefined}
              value={title}
              type="text"
              label="Title"
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface DistrictAdminUpdateProps {
  isShow: boolean;
  first_name: string;
  last_name: string;
  input_email: string;
  input_title: string;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const DistrictAdminUpdateModal: React.FC<DistrictAdminUpdateProps> = ({
  isShow,
  loading,
  first_name,
  last_name,
  input_email,
  input_title,
  onCancel,
  onUpdate,
}) => {
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [email, setEmail] = useState(input_email);
  const [title, setTitle] = useState(input_title);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { management } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (management.districtAdminsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.districtAdminsError,
      });
    }
  }, [management.districtAdminsError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email, title]);

  const checkDisabled = () => {
    if (
      firstName &&
      lastName &&
      email &&
      (firstName !== first_name ||
        lastName !== last_name ||
        email !== input_email ||
        input_title !== title)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearData();
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        title: title?.trim(),
      };
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.UPDATE_DISTRICT_ADMIN_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit District Admin</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              autoFocus={true}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="email"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["email"] !== undefined}
              value={email}
              type="text"
              label="Email"
              onChange={handleOnChange}
              error={clientErrors.email}
              errorMessage={clientErrors.email}
              overlayClick={() => {
                setClientErrors({
                  ...clientErrors,
                  email:
                    clientErrors.email === "Invalid Email"
                      ? "required"
                      : undefined,
                });
              }}
            />
            <Input
              fieldName="title"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["title"] !== undefined}
              value={title}
              type="text"
              label="Title"
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface DistrictUpdateProps {
  isShow: boolean;
  district_name: string;
  // file: string;
  id: number;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const DistrictUpdateModal: React.FC<DistrictUpdateProps> = ({
  isShow,
  loading,
  district_name,
  // file,
  id,
  onCancel,
  onUpdate,
}) => {
  const [name, setName] = useState(district_name);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  useEffect(() => {
    if (management.districtsError) {
      setClientErrors({
        ...clientErrors,
        name: management.districtsError,
      });
      if (management.districtsError) {
        setIsSubmitError(true);
      }
    }
  }, [management.districtsError]);

  useEffect(() => {
    checkDisabled();
  }, [name]);

  const checkDisabled = () => {
    if (name !== district_name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    dispatch({
      type: ActionType.UPDATE_DISTRICT_ERROR,
      payload: null,
    });
    const errors = handleValidation();
    let isError = errors.name;

    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        name,
        // logo_filename: ""
      };
      onUpdate(data);
    }
  };

  const handleValidation = () => {
    const errors: any = {};
    if (name.trim().length === 0) {
      errors["name"] = "required";
    }
    return errors;
  };

  const handleOnChange = (name: string, value: string) => {
    name === "name" && setName(value);
    if (isSubmitError) {
      const isValid = value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.UPDATE_DISTRICT_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };
  // const doUploadLogo = async (files: any) => {
  //   let data = {
  //     file: files[0],
  //     id: id
  //   };
  //   await uploadDistrictLogo(data);
  // };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit District</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <CustomInput
              fieldName="name"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["name"] !== undefined}
              value={name}
              autoFocus={true}
              type="text"
              label="Name"
              onChange={handleOnChange}
              error={clientErrors.name}
              errorMessage={clientErrors.name}
              overlayClick={() => {
                setClientErrors({});
              }}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface DistrictLogoUpdateProps {
  isShow: boolean;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
  onRemoveLogo: () => void;
}

export const DistrictLogoUpdateModal: React.FC<DistrictLogoUpdateProps> = ({
  isShow,
  loading,
  onCancel,
  onUpdate,
  onRemoveLogo,
}) => {
  const [file, setFile] = useState<any | null>(null);
  const [clientErrors, setClientErrors] = useState({
    file: "",
  });

  const logo = useSelector(getDistrictLogoSelector);
  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const onSubmit = () => {
    let errorsCopy = { ...clientErrors };
    let isError = false;
    if (!file) {
      errorsCopy.file = "File is Required";
      isError = true;
    }
    if (isError) {
      setClientErrors({ ...errorsCopy });
    } else {
      onUpdate(file);
    }
  };

  // const doUploadLogo = async (files: any) => {
  //   let data = {
  //     file: files[0],
  //     id: id
  //   };
  //   await uploadDistrictLogo(data);
  // };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Upload District Logo</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <small className="px-2 text-danger">{clientErrors.file}</small>
            <p>Drag a logo to the area below or click Choose File</p>
            <div className="uploads">
              <div
                {...getRootProps({ className: "uploads__content bordered" })}
              >
                <input {...getInputProps()} />
                <div className="support__upload-wrapper">
                  <p>Drag Here</p>
                </div>
              </div>
            </div>
            <p className="uploads-file-name">{file?.name}</p>
            <div className="uploads__pdf-button d-flex justify-content-center">
              <button
                className="btn success-btn"
                type="button"
                onClick={() => {
                  open();
                }}
              >
                Choose File
              </button>
            </div>
          </div>
          <div className="modal__footer">
            <Button
              buttonText="Remove Current"
              onClick={() => onRemoveLogo()}
              className="btn outline-btn outline-btn-md mr-3"
              disabled={logo ? false : true}
              type="button"
            />
            <Button
              buttonText="Cancel"
              onClick={onCancel}
              className="btn cancel-btn mx-3"
              disabled={loading}
              type="button"
            />
            <Button
              buttonText="Save"
              onClick={onSubmit}
              className={`btn success-btn btn-lg ${
                (loading || !file) && "btn-disabled"
              }`}
              // disabled={loading || !file}
              loading={loading}
              type="button"
              id="save-button"
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

interface SchoolUpdateProps {
  isShow: boolean;
  school: any;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
  isSummativeShow?: boolean;
  superAdmins?: any[];
}

export const SchoolUpdateModal: React.FC<SchoolUpdateProps> = ({
  isShow,
  loading,
  school,
  onCancel,
  onUpdate,
  isSummativeShow = true,
  superAdmins,
}) => {
  console.log(school, "INMODAL..............................INMODAL");
  const [schoolName, setSchoolName] = useState(school?.school_name);
  const [addressOne, setAddressOne] = useState(
    school?.address_1 || school?.address
  );
  const [addressTwo, setAddressTwo] = useState(school?.address_2);
  const [zip, setZip] = useState(school?.zip);
  const [phone, setPhone] = useState(school?.phone);
  const [city, setCity] = useState(school?.city);
  const [state, setState] = useState(school?.state);
  const [stdLicense, setStdLicense] = useState(school?.student_licenses);
  const [facilitatorId, setFacilitatorId] = useState<any>(
    school?.admin_facilitator_id || "0"
  );
  const [isDisabled, setDisabled] = useState(true);
  const [defaultSummatives, setDefaultSummatives] = useState<null | any>(
    school?.summatives_info
  );
  const [defaults, setDefaults] = useState<null | any>(null);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const userRole = cookie.get(AppCookies.role);
  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  const formatOptionLabel = (
    { label, first_name, last_name }: any,
    metaData: any
  ) => {
    const initials = `${first_name.length > 0 ? first_name[0] : ""}${
      last_name.length > 0 ? last_name[0] : ""
    }`;
    return metaData.context === "menu" ? (
      <div>
        <span>{label}</span>
      </div>
    ) : (
      <div>
        <span>{initials}</span>
      </div>
    );
  };

  const customStyles = {
    control: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 200,
      ...provided,
      height: 42,
      borderRadius: 8,
    }),
  };

  useEffect(() => {
    const fetch = async () => {
      const summatives = await getDefaultSummatives();
      setDefaults(summatives.data);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (management.schoolsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        schoolName: management.schoolsError,
      });
    }
  }, [management.schoolsError]);

  useEffect(() => {
    checkDisabled();
  }, [
    schoolName,
    addressOne,
    zip,
    phone,
    city,
    state,
    stdLicense,
    addressTwo,
    facilitatorId,
  ]);

  const checkDisabled = () => {
    if (
      schoolName &&
      stdLicense &&
      (school?.school_name !== schoolName ||
        school?.student_licenses !== stdLicense ||
        addressOne !== (school?.address_1 || school?.address) ||
        addressTwo !== school?.address_2 ||
        zip !== school?.zip ||
        phone !== school?.phone ||
        city !== school?.city ||
        state !== school?.state ||
        facilitatorId !== school?.admin_facilitator_id)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearData();
    const errors = validateSchoolFields(
      schoolName,
      // addressOne,
      // addressTwo,
      // zip,
      // phone,
      // city,
      // state,
      stdLicense,
      defaultSummatives
    );
    const isError = errors.assessment || errors.schoolName || errors.stdLicense;

    if (isDisabled && !isError) {
      return handleCancel();
    }

    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        name: schoolName,
        address_1: addressOne,
        address_2: addressTwo,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        student_licenses: Number(stdLicense),
        has_pdf_access: true,
        summatives: [...defaultSummatives],
        facilitator_id: facilitatorId,
      };
      onUpdate(data);
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.UPDATE_SCHOOLS_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const handleOnChange = (name: string, value: string) => {
    name === "schoolName" && setSchoolName(value);
    name === "addressOne" && setAddressOne(value);
    name === "zip" && setZip(value);
    name === "addressTwo" && setAddressTwo(value);
    name === "phone" && setPhone(value);
    name === "city" && setCity(value);
    name === "state" && setState(value);
    name === "stdLicense" && setStdLicense(value);
    name === "facilitatorId" && setFacilitatorId(parseInt(value));
    if (isSubmitError) {
      const isValid = value.trim().length > 0;
      if (
        clientErrors[name] === undefined &&
        isValid &&
        name === "addressTwo"
      ) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const doChangeDate = (e: any, i: number) => {
    let summativesCopy = [...defaultSummatives];
    if (summativesCopy[i][e.target.name] !== e.target.value) {
      setDisabled(false);
    }

    let disableButton = false;
    summativesCopy.map((item, index) => {
      if (
        (item["edit_end_date"] &&
          !moment(
            e.target.name === "edit_end_date" && index === i
              ? e.target.value
              : item["edit_end_date"],
            "M-D-YY",
            true
          ).isValid()) ||
        (item["monitor_end_date"] &&
          !moment(
            e.target.name === "monitor_end_date" && index === i
              ? e.target.value
              : item["monitor_end_date"],
            "M-D-YY",
            true
          ).isValid()) ||
        (item["start_date"] &&
          !moment(
            e.target.name === "start_date" && index === i
              ? e.target.value
              : item["start_date"],
            "M-D-YY",
            true
          ).isValid())
      ) {
        if (
          item.display_label === "A2 for 3-8 R/M" &&
          e.target.name === "monitor_end_date" &&
          e.target.value === ""
        ) {
          disableButton = false;
        } else if (
          item.display_label === "A2 for 3-8 R/M" &&
          e.target.name === "edit_end_date" &&
          e.target.value === ""
        ) {
          disableButton = false;
        } else {
          console.log(12);
          disableButton = true;
        }
      }
    });

    setDisabled(disableButton);

    if (defaults[i][e.target.name] !== e.target.value) {
      summativesCopy[i]["edit_date_type"] = "CUSTOM";
      summativesCopy[i]["monitor_date_type"] = "CUSTOM";
      summativesCopy[i]["start_date_type"] = "CUSTOM";
    } else {
      summativesCopy[i]["edit_date_type"] = "DEFAULT";
      summativesCopy[i]["monitor_date_type"] = "DEFAULT";
      summativesCopy[i]["start_date_type"] = "DEFAULT";
    }
    summativesCopy[i][e.target.name] = e.target.value;

    setDefaultSummatives([...summativesCopy]);
    // console.log(summativesCopy , "Again.........")
  };

  const onClickReset = async () => {
    const defaultsData = defaults.map((item: any) => {
      item.edit_date_type = "DEFAULT";
      item.monitor_date_type = "DEFAULT";
      item.start_date_type = "DEFAULT";
      return item;
    });
    setDefaultSummatives(defaultsData);
    setDisabled(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal
      contentClassName="modal-content-edit"
      className="modal-edit-container"
      centered
      show={isShow}
      onEscapeKeyDown={handleCancel}
    >
      <div>
        <div className="modal__header justify-content-between">
          <h6 className="modal__header-title">Edit School</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <CustomInput
                  fieldName="schoolName"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["schoolName"] !== undefined}
                  value={schoolName}
                  type="text"
                  label="School Name"
                  onChange={handleOnChange}
                  error={clientErrors.schoolName}
                  errorMessage={
                    clientErrors.schoolName !== "required"
                      ? clientErrors.schoolName
                      : ""
                  }
                  overlayClick={() => {
                    setClientErrors({
                      ...clientErrors,
                      schoolName: "required",
                    });
                  }}
                />
              </Col>
              <Col sm={6} md={6}></Col>
            </Row>
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <Input
                  fieldName="addressOne"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["addressOne"] !== undefined}
                  value={addressOne}
                  type="text"
                  label="Address 1"
                  onChange={handleOnChange}
                  error={clientErrors.addressOne}
                />
              </Col>
              <Col sm={6} md={6} className="ps-12 ps-sm-2">
                <Input
                  fieldName="zip"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["zip"] !== undefined}
                  value={zip}
                  type="text"
                  label="Zip"
                  onChange={handleOnChange}
                  error={clientErrors.zip}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <Input
                  fieldName="addressTwo"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["addressTwo"] !== undefined}
                  value={addressTwo}
                  type="text"
                  label="Address 2"
                  onChange={handleOnChange}
                  error={clientErrors.addressTwo}
                />
              </Col>
              <Col sm={6} md={6} className="ps-12 ps-sm-2">
                <Input
                  fieldName="phone"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["phone"] !== undefined}
                  value={phone}
                  type="text"
                  label="Phone"
                  onChange={handleOnChange}
                  error={clientErrors.phone}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4} className="pe-12 pe-sm-2">
                <Input
                  fieldName="city"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["city"] !== undefined}
                  value={city}
                  type="text"
                  label="City"
                  onChange={handleOnChange}
                  error={clientErrors.city}
                />
              </Col>
              <Col xs={6} sm={6} md={2} className="pe-sm-2 ps-12 ps-sm-2 col-12">
                <Input
                  fieldName="state"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["state"] !== undefined}
                  value={state}
                  type="text"
                  label="State"
                  onChange={handleOnChange}
                  error={clientErrors.state}
                />
              </Col>
              <Col
                xs={6}
                sm={6}
                md={
                  clientErrors["stdLicense"] &&
                  clientErrors.stdLicense !== "required"
                    ? 6
                    : 3
                }
                className="ps-md-2 ps-12 col-12 pe-2"
              >
                {userRole === "Super Admin" && (
                  <CustomInput
                    fieldName="stdLicense"
                    isSubmitError={isSubmitError}
                    errorPresentBefore={
                      clientErrors["stdLicense"] !== undefined
                    }
                    value={stdLicense}
                    type="number"
                    label="Student Licenses"
                    onChange={handleOnChange}
                    onKeyDownProp={(event: any) => checkIfNumber(event)}
                    error={clientErrors.stdLicense}
                    errorMessage={
                      clientErrors.stdLicense !== "required"
                        ? clientErrors.stdLicense
                        : ""
                    }
                    overlayClick={() => {
                      setClientErrors({
                        ...clientErrors,
                        stdLicense: "required",
                      });
                    }}
                  />
                )}
              </Col>
              <Col
                xs={6}
                sm={6}
                md={
                  clientErrors["stdLicense"] &&
                  clientErrors.stdLicense !== "required"
                    ? 6
                    : 3
                }
                className="ps-sm-2 ps-12 mt-1 col-12"
              >
                {userRole === "Super Admin" && (
                  // <SelectInput
                  //   fieldName="facilitatorId"
                  //   isSubmitError={isSubmitError}
                  //   value={facilitatorId}
                  //   label="Facilitator"
                  //   onChange={handleOnChange}
                  //   error={clientErrors.facilitatorId}
                  //   errorPresentBefore={false}
                  //   defaultTitle={"Select Super Admin"}
                  //   options={superAdmins?.map((item: any) => ({...item, name: `${item.first_name}${item.last_name ? ` ${item.last_name}` : ''}`})) || []}
                  // />
                  <div className="facilitator-select position-relative">
                    <label className="facilitator-select__label">
                      Facilitator
                    </label>
                    <Select
                      isSearchable
                      options={
                        superAdmins?.map((item: any) => ({
                          ...item,
                          name: `${item.first_name}${
                            item.last_name ? ` ${item.last_name}` : ""
                          }`,
                          label: `${item.first_name}${
                            item.last_name ? ` ${item.last_name}` : ""
                          }`,
                          value: item.id,
                        })) || []
                      }
                      placeholder="None"
                      onChange={(data: any) =>
                        handleOnChange("facilitatorId", data.value)
                      }
                      className="facilitator-select__wrapper"
                      classNamePrefix="facilitator-select"
                      formatOptionLabel={formatOptionLabel}
                      components={{
                        IndicatorSeparator: () => null,
                        // DropdownIndicator: (props) => (
                        //   <div {...props}>
                        //     <img src={DrdownIcon} />
                        //   </div>
                        // )
                      }}
                      styles={customStyles}
                      value={superAdmins?.find(
                        (item: any) => item.id === facilitatorId
                      )}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>

          {isSummativeShow ? (
            <div className="modal__assesment">
              <div className="d-flex align-items-center w-100">
                <div className="modal__assesment-availablity">
                  <p>
                    Assessment Availability
                    {defaultSummatives &&
                    defaultSummatives?.filter(
                      (item: any) =>
                        (item?.edit_date_type &&
                          item?.edit_date_type !== "DEFAULT") ||
                        (item?.monitor_date_type &&
                          item?.monitor_date_type !== "DEFAULT") ||
                        (item?.start_date_type &&
                          item?.start_date_type !== "DEFAULT")
                    ).length ? (
                      <div className="pill-warning">Custom</div>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="modal__assesment-date">
                  <p>Date format is MM/DD/YY</p>
                </div>
                <p className="text-danger ms-auto mb-0 shadow-text fw-bold">
                  {clientErrors.assessment}
                </p>
              </div>
              <div className="modal__table-container table-responsive">
                <table className="table modal__table table-borderless">
                  <thead>
                    <tr>
                      <th className="modal__table-name">Assessment Name</th>
                      <th className="modal__table-monitor">Monitor</th>
                      <th className="modal__table-edit">Edit Button</th>
                    </tr>
                  </thead>
                  <tbody>
                    {defaultSummatives && defaultSummatives.length
                      ? defaultSummatives?.map((item: any, i: number) => {
                          return (
                            <tr key={i} className="modal__table-row">
                              <td>
                                <div className="name-data">
                                  {(item?.edit_date_type &&
                                    item?.edit_date_type !== "DEFAULT") ||
                                  (item?.monitor_date_type &&
                                    item?.monitor_date_type !== "DEFAULT") ||
                                  (item?.start_date_type &&
                                    item?.start_date_type !== "DEFAULT") ? (
                                    <span className="pill-circle"></span>
                                  ) : (
                                    ""
                                  )}
                                  <p>{item?.display_label}</p>
                                </div>
                              </td>
                              <td>
                                <div className="monitor-data">
                                  <span className="pill-success">From</span>
                                  {/* <p>{item?.start_date}</p> */}
                                  {item?.start_date !== null ? (
                                    <div className="modal__table-inputs">
                                      <input
                                        onChange={(e) => doChangeDate(e, i)}
                                        name="start_date"
                                        value={item?.start_date}
                                      />
                                      <small>
                                        {moment(
                                          item?.start_date,
                                          "M-D-YY",
                                          true
                                        ).isValid() ? (
                                          ""
                                        ) : (
                                          <span className="error-circle"></span>
                                        )}
                                      </small>
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                  <span className="pill-until">Until</span>
                                </div>
                              </td>
                              <td>
                                <div className="edit-data">
                                  {/* <p>{item?.monitor_end_date}</p> */}
                                  {item?.monitor_end_date !== null ? (
                                    <div className="modal__table-inputs">
                                      <input
                                        onChange={(e) => doChangeDate(e, i)}
                                        name="monitor_end_date"
                                        value={item?.monitor_end_date}
                                      />
                                      <small>
                                        {moment(
                                          item?.monitor_end_date,
                                          "M-D-YY",
                                          true
                                        ).isValid() ||
                                        (item.display_label ===
                                          "A2 for 3-8 R/M" &&
                                          item?.monitor_end_date === "") ? (
                                          ""
                                        ) : (
                                          <span className="error-circle"></span>
                                        )}
                                      </small>
                                    </div>
                                  ) : (
                                    <span className="empty-data">-</span>
                                  )}
                                  <span className="pill-until">Until</span>
                                  {item?.edit_end_date !== null ? (
                                    <div className="modal__table-inputs">
                                      <input
                                        onChange={(e) => doChangeDate(e, i)}
                                        name="edit_end_date"
                                        value={item?.edit_end_date}
                                      />
                                      <small>
                                        {moment(
                                          item?.edit_end_date,
                                          "M-D-YY",
                                          true
                                        ).isValid() ||
                                        (item.display_label ===
                                          "A2 for 3-8 R/M" &&
                                          item?.edit_end_date === "") ? (
                                          ""
                                        ) : (
                                          <span className="error-circle"></span>
                                        )}
                                      </small>
                                    </div>
                                  ) : (
                                    <span className="empty-data lg">-</span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="modal__footer with-padding justify-content-between">
          {isSummativeShow &&
          defaultSummatives &&
          defaultSummatives?.filter(
            (item: any) =>
              (item?.edit_date_type && item?.edit_date_type !== "DEFAULT") ||
              (item?.monitor_date_type &&
                item?.monitor_date_type !== "DEFAULT") ||
              (item?.start_date_type && item?.start_date_type !== "DEFAULT")
          ).length ? (
            <button
              disabled={loading}
              onClick={onClickReset}
              className="btn outline-btn"
            >
              Reset Availability to Default
            </button>
          ) : (
            ""
          )}
          <div className="ml-auto inner-buttons">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={loading}
              type="button"
            />
            <Button
              buttonText="Save"
              onClick={() => !isDisabled && onSubmit()}
              className={`btn success-btn btn-lg ${
                (loading || isDisabled) && "btn-disabled"
              }`}
              loading={loading}
              type="button"
              id="save-button"
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export const SchoolUpdateCSVModal: React.FC<SchoolUpdateProps> = ({
  isShow,
  loading,
  school,
  onCancel,
  onUpdate,
}) => {
  console.log(school, "INMODAL..............................INMODAL");
  const [schoolName, setSchoolName] = useState(school?.school_name);
  const [addressOne, setAddressOne] = useState(
    school?.address_1 || school?.address
  );
  const [addressTwo, setAddressTwo] = useState(school?.address_2);
  const [zip, setZip] = useState(school?.zip);
  const [phone, setPhone] = useState(school?.phone);
  const [city, setCity] = useState(school?.city);
  const [state, setState] = useState(school?.state);
  const [stdLicense, setStdLicense] = useState(school?.student_licenses);
  const [isDisabled, setDisabled] = useState(true);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const userRole = cookie.get(AppCookies.role);

  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [schoolName, addressOne, zip, phone, city, state, stdLicense, addressTwo]);

  const checkDisabled = () => {
    if (
      schoolName &&
      (school?.school_name !== schoolName ||
        school?.student_licenses !== stdLicense ||
        addressOne !== (school?.address_1 || school?.address) ||
        addressTwo !== school?.address_2 ||
        zip !== school?.zip ||
        phone !== school?.phone ||
        city !== school?.city ||
        state !== school?.state)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    const errors = validateSchoolFields(schoolName, stdLicense, []);
    const isError = errors.schoolName || errors.stdLicense;

    if (isDisabled && !isError) {
      return handleCancel();
    }

    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        name: schoolName,
        address_1: addressOne,
        address_2: addressTwo,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        student_licenses: Number(stdLicense),
      };
      onUpdate(data);
      handleCancel();
    }
  };

  const clearData = () => {
    setSchoolName("");
    setAddressOne("");
    setAddressTwo("");
    setPhone("");
    setZip("");
    setCity("");
    setState("");
    setStdLicense("");
    setDisabled(true);
    setClientErrors({});
    setIsSubmitError(false);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const handleOnChange = (name: string, value: string) => {
    name === "schoolName" && setSchoolName(value);
    name === "addressOne" && setAddressOne(value);
    name === "zip" && setZip(value);
    name === "addressTwo" && setAddressTwo(value);
    name === "phone" && setPhone(value);
    name === "city" && setCity(value);
    name === "state" && setState(value);
    name === "stdLicense" && setStdLicense(value);
    if (isSubmitError) {
      const isValid = value.trim().length > 0;
      if (
        clientErrors[name] === undefined &&
        isValid &&
        name === "addressTwo"
      ) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return (
    <CustomModal
      contentClassName="modal-content-edit"
      className="modal-edit-container"
      centered
      show={isShow}
      onEscapeKeyDown={handleCancel}
    >
      <div>
        <div className="modal__header justify-content-between">
          <h6 className="modal__header-title">Edit School</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <Input
                  fieldName="schoolName"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["schoolName"] !== undefined}
                  value={schoolName}
                  autoFocus={true}
                  type="text"
                  label="School Name"
                  onChange={handleOnChange}
                  error={clientErrors.schoolName}
                />
              </Col>
              <Col sm={6} md={6}></Col>
            </Row>
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <Input
                  fieldName="addressOne"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["addressOne"] !== undefined}
                  value={addressOne}
                  type="text"
                  label="Address 1"
                  onChange={handleOnChange}
                  error={clientErrors.addressOne}
                />
              </Col>
              <Col sm={6} md={6} className="ps-12 ps-sm-2">
                <Input
                  fieldName="zip"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["zip"] !== undefined}
                  value={zip}
                  type="text"
                  label="Zip"
                  onChange={handleOnChange}
                  error={clientErrors.zip}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <Input
                  fieldName="addressTwo"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["addressTwo"] !== undefined}
                  value={addressTwo}
                  type="text"
                  label="Address 2"
                  onChange={handleOnChange}
                  error={clientErrors.addressTwo}
                />
              </Col>
              <Col sm={6} md={6} className="ps-12 ps-sm-2">
                <Input
                  fieldName="phone"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["phone"] !== undefined}
                  value={phone}
                  type="text"
                  label="Phone"
                  onChange={handleOnChange}
                  error={clientErrors.phone}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4} className="pe-12 pe-sm-2">
                <Input
                  fieldName="city"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["city"] !== undefined}
                  value={city}
                  type="text"
                  label="City"
                  onChange={handleOnChange}
                  error={clientErrors.city}
                />
              </Col>
              <Col xs={6} sm={2} md={2} className="pe-sm-2 ps-12 ps-sm-2">
                <Input
                  fieldName="state"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["state"] !== undefined}
                  value={state}
                  type="text"
                  label="State"
                  onChange={handleOnChange}
                  error={clientErrors.state}
                />
              </Col>
              <Col xs={6} sm={4} md={3} className="ps-0 ps-sm-2">
                {userRole === "Super Admin" && (
                  <Input
                    fieldName="stdLicense"
                    isSubmitError={isSubmitError}
                    errorPresentBefore={
                      clientErrors["stdLicense"] !== undefined
                    }
                    value={stdLicense}
                    type="number"
                    label="Student Licenses"
                    onChange={handleOnChange}
                    onKeyDown={(event: any) => checkIfNumber(event)}
                    error={clientErrors.stdLicense}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
        <div className="modal__footer with-padding justify-content-between">
          <div className="ml-auto">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={loading}
              type="button"
            />
            <Button
              buttonText="Save"
              onClick={onSubmit}
              className={`btn success-btn btn-lg ${
                (loading || isDisabled) && "btn-disabled"
              }`}
              loading={loading}
              type="button"
              id="save-button"
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

interface ClassUpdateProps {
  isShow: boolean;
  classRoom: any;
  schoolId: any;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
  teacherId: any;
  classId?: any;
}

export const CLassUpdateModal: React.FC<ClassUpdateProps> = ({
  isShow,
  loading,
  schoolId,
  classRoom,
  onCancel,
  onUpdate,
  teacherId,
  classId,
}) => {
  const [adminList, setAdminList] = useState<null | any>(null);
  const [gradeList, setGradeList] = useState<null | any>(null);
  const [subjectList, setSubjectList] = useState<null | any>(null);
  const [grade, setGrade] = useState(classRoom?.grade);
  const [notes, setNotes] = useState(classRoom.notes);
  const [selectedSubject, setSelectedSubject] = useState(
    classRoom?.fk_subject_id
  );
  const [selectedPeriod, setSelectedPeriod] = useState(classRoom?.period);
  const [isDisabled, setDisabled] = useState(true);
  const [disableGradesAndSubjects, setDisableGradesAndSubjects] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownShow, setDropdownShow] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState<any | null>(null);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [className, setClassName] = useState("");
  const [preAdmin, setPreAdmin] = useState(classRoom?.teacher_reference_name);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    !isShow && handleCancel();
    classroomTestsExists();
  }, [isShow]);
  useEffect(() => {
    if (subjectList && subjectList.length) {
      const subjectSelect = subjectList.find(
        (sub: any) => parseInt(sub.id) === parseInt(selectedSubject)
      );
      setSubject(subjectSelect);
    }
  }, [subjectList, selectedSubject]);

  useEffect(() => {
    checkDisabled();
    if ((currentAdmin || preAdmin) && grade && subject) {
      setClassName(
        currentAdmin
          ? `${currentAdmin.last_name} ${currentAdmin.first_name[0]} ${
              subject.name
            } ${parseInt(grade) === 9 ? "K" : grade}`
          : preAdmin.substring(0, preAdmin.indexOf(",") + 3).replace(",", "") +
              ` ${subject.name} ${parseInt(grade) === 9 ? "K" : grade}`
      );
      if (selectedPeriod) {
        if (selectedPeriod === "0") {
          setClassName(
            currentAdmin
              ? `${currentAdmin.last_name} ${currentAdmin.first_name[0]} ${
                  subject.name
                } ${parseInt(grade) === 9 ? "K" : grade}`
              : preAdmin
                  .substring(0, preAdmin.indexOf(",") + 3)
                  .replace(",", "") +
                  ` ${subject.name} ${parseInt(grade) === 9 ? "K" : grade}`
          );
        } else {
          setClassName(
            currentAdmin
              ? `${currentAdmin.last_name} ${currentAdmin.first_name[0]} ${
                  subject.name
                } ${parseInt(grade) === 9 ? "K" : grade} - ${selectedPeriod}`
              : preAdmin
                  .substring(0, preAdmin.indexOf(",") + 3)
                  .replace(",", "") +
                  ` ${subject.name} ${
                    parseInt(grade) === 9 ? "K" : grade
                  } - ${selectedPeriod}`
          );
        }
      }
      if (notes) {
        setClassName(
          currentAdmin
            ? `${currentAdmin.last_name} ${currentAdmin.first_name[0]} ${
                subject.name
              } ${parseInt(grade) === 9 ? "K" : grade} - (${notes})`
            : preAdmin
                .substring(0, preAdmin.indexOf(",") + 3)
                .replace(",", "") +
                ` ${subject.name} ${
                  parseInt(grade) === 9 ? "K" : grade
                } - (${notes})`
        );
      }
      if (
        (notes && selectedPeriod && selectedPeriod !== "0") ||
        (selectedPeriod && selectedPeriod !== "0")
      ) {
        let initialClassName = currentAdmin
          ? `${currentAdmin.last_name} ${currentAdmin.first_name[0]} ${
              subject.name
            } ${parseInt(grade) === 9 ? "K" : grade}`
          : preAdmin.substring(0, preAdmin.indexOf(",") + 3).replace(",", "") +
            ` ${subject.name} ${parseInt(grade) === 9 ? "K" : grade}`;
        const appendNotes = notes ? ` (${notes})` : ``;
        if (+selectedPeriod === 1) {
          initialClassName = `${initialClassName} - ${selectedPeriod}st Hr${appendNotes}`;
        } else if (+selectedPeriod === 2) {
          initialClassName = `${initialClassName} - ${selectedPeriod}nd Hr${appendNotes}`;
        } else if (+selectedPeriod === 3) {
          initialClassName = `${initialClassName} - ${selectedPeriod}rd Hr${appendNotes}`;
        } else {
          initialClassName = `${initialClassName} - ${selectedPeriod}th Hr${appendNotes}`;
        }
        setClassName(initialClassName);
      }
    }
  }, [selectedPeriod, grade, currentAdmin, notes, subject]);

  const classroomTestsExists = async () => {
    let resp = await checkIfClassroomHasTests(classRoom.id);
    setDisableGradesAndSubjects(resp.data);
  };

  const checkDisabled = () => {
    if (
      (Number(selectedSubject) !== 0 &&
        Number(selectedSubject) !== classRoom?.fk_subject_id) ||
      (Number(selectedPeriod) !== 0 &&
        Number(selectedPeriod) !== classRoom?.period) ||
      (Number(grade) !== 0 && Number(grade) !== classRoom?.grade) ||
      currentAdmin !== null ||
      notes !== classRoom.notes
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    const getAllSubjects = async () => {
      setDataLoaded(true);
      let subjectResponse = await getSubjectList();
      let gradeResponse = await getGradeList();
      let adminResponse = await getAllClassAdmins(schoolId ?? 0);
      setAdminList(adminResponse?.data?.classroom_admins);
      setSubjectList(subjectResponse?.data?.subjects);
      setGradeList(gradeResponse?.data?.grades);
      setDataLoaded(false);
    };
    getAllSubjects();
  }, []);

  useEffect(() => {
    adminList &&
      adminList.length &&
      adminList.map((admin: any) => {
        if (admin.id === teacherId) {
          setPreAdmin(`${admin.last_name}, ${admin.first_name}`);
          setCurrentAdmin(admin);
        }
        return true;
      });
  }, [adminList]);

  const onSubmit = () => {
    const errors = validateClassFields(
      selectedSubject,
      grade,
      currentAdmin,
      selectedPeriod
    );
    let isError =
      errors.selectedSubject || errors.grade || errors.selectedPeriod;
    // errors.currentAdmin;
    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let subject = subjectList.filter(
        (item: any) => item.id === Number(selectedSubject)
      )[0]?.name;

      console.log(selectedPeriod, periodList);
      let newPeriod =
        selectedPeriod && selectedPeriod !== "0"
          ? periodList.filter(
              (item: any) => parseInt(item.name) === parseInt(selectedPeriod)
            )[0].value
          : "";
      let newClassName = className;

      let data: any = {
        grade_id: Number(grade),
        subject_id: Number(selectedSubject),
        name: newClassName,
        notes: notes,
        period: null,
        admin_name: currentAdmin
          ? `${currentAdmin.last_name}, ${currentAdmin.first_name}`
          : preAdmin,
        admin_id: currentAdmin.id,
      };

      if (selectedPeriod !== "0") {
        data.period = Number(selectedPeriod);
      }
      onUpdate(data);
      handleCancel();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const onSetCurrentAdmin = (item: any) => {
    setCurrentAdmin(item);
    setDropdownShow(false);
    if (isSubmitError) {
      setClientErrors({ ...clientErrors, currentAdmin: "" });
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "grade" && setGrade(value);
    name === "selectedSubject" && setSelectedSubject(value);
    name === "selectedPeriod" && setSelectedPeriod(value);
    name === "notes" && setNotes(value);
    if (isSubmitError) {
      const isValid =
        name === "grade" || name === "selectedSubject"
          ? value !== "0"
          : name === "notes"
          ? true
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePressClass);
    return () => {
      document.removeEventListener("keydown", handlePressClass);
    };
  }, []);

  const filterdSubjects =
    grade === "5" || grade === "8" || grade === 5 || grade === 8
      ? subjectList
      : subjectList?.filter((item: any) => item.name !== "Science");
  const gradeFilters =
    classRoom?.grade === 9
      ? gradeList
      : gradeList?.filter((item: any) => item.id !== 9);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Class</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            {!dataLoaded && (
              <AdminsSelectClass
                options={adminList}
                disableHandler={false}
                onChangeHandler={(data: any) => onSetCurrentAdmin(data?.item)}
                noOptionsMessage={
                  <p className="no-admins">No Class Admins Available</p>
                }
                open={isDropdownShow}
                setOpen={setDropdownShow}
                isId={!!currentAdmin?.id}
                id={currentAdmin?.id}
                onSave={onSubmit}
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["currentAdmin"] !== undefined}
                error={clientErrors.currentAdmin}
              />
            )}
            <SelectInput
              fieldName="grade"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["grade"] !== undefined}
              value={grade}
              label="Grade"
              onChange={handleOnChange}
              error={clientErrors.grade}
              options={gradeFilters}
              defaultTitle="Select Grade"
              disabled={disableGradesAndSubjects}
            />
            <SelectInput
              fieldName="selectedSubject"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["selectedSubject"] !== undefined}
              value={selectedSubject}
              label="Subject"
              onChange={handleOnChange}
              error={clientErrors.selectedSubject}
              options={filterdSubjects}
              defaultTitle="Select Subject"
              disabled={disableGradesAndSubjects}
            />
            <SelectInput
              fieldName="selectedPeriod"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["selectedPeriod"] !== undefined}
              value={selectedPeriod}
              label="Period"
              onChange={handleOnChange}
              error={clientErrors.selectedPeriod}
              options={[...periodList]}
              notDisableFirst={true}
              defaultTitle=" Select Period"
            />
            <Input
              fieldName="notes"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["notes"] !== undefined}
              value={notes}
              label="Notes"
              type="text"
              onChange={handleOnChange}
              margin={true}
              error={clientErrors.notes}
            />
          </div>
        </div>
        {className && <p style={{ margin: "0 0 16px 13px" }}>{className}</p>}

        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface ClassAndTeacherUpdateProps {
  isShow: boolean;
  csvClass: any;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const ClassAndTeacherUpdateModal: React.FC<
  ClassAndTeacherUpdateProps
> = ({ isShow, loading, csvClass, onCancel, onUpdate }) => {
  const [gradeList, setGradeList] = useState<null | any>(null);
  const [subjectList, setSubjectList] = useState<null | any>(null);
  const [grade, setGrade] = useState(
    csvClass.grade === "K" ? "9" : csvClass.grade
  );
  const subjectName =
    csvClass.subject === "M"
      ? "Math"
      : csvClass.subject === "R"
      ? "ELA"
      : "Science";
  const [selectedSubject, setSelectedSubject] = useState(0);
  const [teacherLastName, setTeacherLastName] = useState(
    csvClass?.teacher_last_name
  );
  const [teacherFirtsName, setTeacherFirtsName] = useState(
    csvClass?.teacher_first_name
  );
  const [teacherEmail, setTeacherEmail] = useState(csvClass?.teacher_email);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const getAllSubjects = async () => {
      let subjectResponse = await getSubjectList();
      let gradeResponse = await getGradeList();
      setSelectedSubject(
        subjectResponse?.data?.subjects.find(
          (sub: any) => sub.name === subjectName
        ).id
      );
      setSubjectList(subjectResponse?.data?.subjects);
      setGradeList(gradeResponse?.data?.grades);
      console.log(subjectResponse?.data?.subjects);
      console.log(gradeResponse?.data?.grades);
    };
    getAllSubjects();
  }, []);

  const onSubmit = () => {
    let errorsCopy = { ...clientErrors };
    let isError = false;
    if (!teacherFirtsName) {
      errorsCopy.teacherFirtsName = "required";
      isError = true;
    }
    if (!teacherLastName) {
      errorsCopy.teacherLastName = "required";
      isError = true;
    }
    if (!validator.isEmail(teacherEmail)) {
      errorsCopy.teacherEmail = "Invalid Email";
      isError = true;
    }
    if (!teacherEmail) {
      errorsCopy.teacherEmail = "required";
      isError = true;
    }
    // if (Number(grade) > 8 || Number(grade) < 1) {
    //   errorsCopy.grade = "Grade is Invalid";
    //   isError = true;
    // }
    if (!grade) {
      errorsCopy.grade = "Grade is Required";
      isError = true;
    }
    if (isError) {
      setClientErrors({ ...errorsCopy });
      setIsSubmitError(true);
    } else {
      let data = {
        grade: grade === "9" ? "K" : grade,
        subject: subjectList
          .find((item: any) => item.id === Number(selectedSubject))
          ?.name?.slice(0, 1),
        teacher_email: teacherEmail.trim(),
        teacher_first_name: teacherFirtsName.trim(),
        teacher_last_name: teacherLastName.trim(),
      };
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: any) => {
    name === "teacherLastName" && setTeacherLastName(value);
    name === "teacherFirtsName" && setTeacherFirtsName(value);
    name === "teacherEmail" && setTeacherEmail(value);
    name === "grade" && setGrade(value);
    name === "selectedSubject" && setSelectedSubject(value);
    setIsDisabled(false);
    if (isSubmitError) {
      const isValid =
        name === "teacherEmail"
          ? validator.isEmail(value)
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const filterdSubjects =
    grade === "5" || grade === "8" || grade === 5 || grade === 8
      ? subjectList
      : subjectList?.filter((item: any) => item.name !== "Science");
  console.log(1112);
  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Class</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <SelectInput
              fieldName="grade"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["grade"] !== undefined}
              value={grade}
              // autoFocus={true}
              label="Grade"
              onChange={handleOnChange}
              error={clientErrors.grade}
              options={gradeList}
              defaultTitle="Select Grade"
            />
            <Input
              fieldName="teacherLastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["teacherLastName"] !== undefined}
              type="text"
              value={teacherLastName}
              label="Teacher Last Name"
              onChange={handleOnChange}
              error={clientErrors.teacherLastName}
            />
            <Input
              fieldName="teacherFirtsName"
              isSubmitError={isSubmitError}
              errorPresentBefore={
                clientErrors["teacherFirtsName"] !== undefined
              }
              type="text"
              value={teacherFirtsName}
              label="Teacher First Name"
              onChange={handleOnChange}
              error={clientErrors.teacherFirtsName}
            />
            <CustomInput
              fieldName="teacherEmail"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors.teacherEmail !== undefined}
              type="text"
              value={teacherEmail}
              label="Teacher Email"
              onChange={handleOnChange}
              error={clientErrors.teacherEmail}
              errorMessage="Invalid Email"
              overlayClick={() =>
                setClientErrors({
                  ...clientErrors,
                  teacherEmail: "required",
                })
              }
            />
            <SelectInput
              fieldName="selectedSubject"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["selectedSubject"] !== undefined}
              value={selectedSubject}
              label="Subject"
              onChange={handleOnChange}
              error={clientErrors.selectedSubject}
              options={filterdSubjects}
              defaultTitle="Select Subject"
            />
          </div>
        </div>
        <div className="modal__footer">
          <button
            disabled={loading}
            onClick={onCancel}
            className="btn cancel-btn"
          >
            Cancel
          </button>
          <button
            onClick={onSubmit}
            id="save-button"
            className={`btn success-btn btn-lg ${isDisabled && "btn-disabled"}`}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

interface StudentUpdateProps {
  isShow: boolean;
  serverError: null | any;
  first_name: string;
  last_name: string;
  std_id: string;
  allow_calculator?: boolean;
  text_to_speech?: boolean;
  notes?: string;
  classroom_id?: number;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
  showSwitch: boolean;
}

export const StudentUpdateModal: React.FC<StudentUpdateProps> = ({
  isShow,
  loading,
  first_name,
  last_name,
  std_id,
  text_to_speech,
  allow_calculator,
  classroom_id,
  notes = "",
  onCancel,
  onUpdate,
  serverError,
  showSwitch = false,
}) => {
  console.log(first_name, "............................");
  console.log(classroom_id, "............................");
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [schoolStdId, setSchoolStdId] = useState(std_id);
  const [calculator, setCalculator] = useState(allow_calculator);
  const [txtToSpeech, setTxtToSpeech] = useState(text_to_speech);
  const [studentNotes, setStudentNotes] = useState(notes);
  const [classroomId, setClassroomId] = useState(classroom_id);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const management = useSelector((state: any) => state.management);
  const dispatch = useDispatch();

  useEffect(() => {
    if (management.studentUpdateError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        schoolStdId: management.studentUpdateError,
      });
    }
  }, [management.studentUpdateError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, schoolStdId, studentNotes, calculator, txtToSpeech]);

  const checkDisabled = () => {
    if (
      firstName &&
      lastName &&
      schoolStdId &&
      (firstName !== first_name ||
        lastName !== last_name ||
        schoolStdId !== std_id ||
        studentNotes !== notes ||
        txtToSpeech !== text_to_speech ||
        calculator !== allow_calculator)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearData();
    const errors = validateStudentFields(firstName, lastName, schoolStdId);
    const isError = errors.firstName || errors.lastName || errors.schoolStdId;
    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data: any = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        school_student_id: schoolStdId,
      };
      if (allow_calculator !== undefined) {
        data.allow_calculator = calculator;
      }
      if (text_to_speech !== undefined) {
        data.text_to_speech_enabled = txtToSpeech;
      }
      if (studentNotes !== "") {
        data.notes = studentNotes;
      }

      if (classroomId) {
        data.classroom_id = classroomId;
      }
      // console.log(text_to_speech)
      // console.log(allow_calculator)
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "schoolStdId" && setSchoolStdId(value);
    name === "notes" && setStudentNotes(value);
    if (isSubmitError) {
      const isValid =
        name === "schoolStdId"
          ? value.length <= 10 && value.length >= 4
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.UPDATE_STUDENTS_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Student</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              autoFocus={true}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="schoolStdId"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["schoolStdId"] !== undefined}
              value={schoolStdId}
              type="text"
              label="Student ID"
              onChange={handleOnChange}
              onKeyDownProp={(event: any) => checkIfNumber(event)}
              onPasteHandler={(event: any)=>handlePaste(event)}
              error={clientErrors.schoolStdId}
              errorMessage={clientErrors.schoolStdId}
              overlayClick={() => {
                setClientErrors({
                  ...clientErrors,
                  schoolStdId:
                    clientErrors.schoolStdId ===
                    "Student ID must be between 4-10 digits"
                      ? "required"
                      : undefined,
                });
              }}
            />
            <Input
              fieldName="notes"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["notes"] !== undefined}
              value={studentNotes}
              type="text"
              label="Notes"
              onChange={handleOnChange}
              error={clientErrors.notes}
            />
            {showSwitch ? (
              <>
                <div className="modal__form-switch">
                  <p>Allow Calculator</p>
                  <Toggle 
                    isSmall 
                    checked = {!!calculator} 
                    onChange={(e)=>setCalculator(!calculator)} 
                    labelClassName="mb-0" />     
                </div>
                <div className="modal__form-switch">
                  <p>Allow Text to Speech</p>
                  <Toggle  
                    isSmall 
                    checked = {!!txtToSpeech} 
                    onChange={(e)=>setTxtToSpeech(!txtToSpeech)} 
                    labelClassName="mb-0"/>               
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface CleverUpdateProps {
  isShow: boolean;
  std_id: string;
  clever_id: string;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const CleverUpdateModal: React.FC<CleverUpdateProps> = ({
  isShow,
  loading,
  std_id,
  clever_id,
  onCancel,
  onUpdate,
}) => {
  const [stdId, setStdId] = useState("");
  const [cleverId, setCleverId] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);

  useEffect(() => {
    !isShow && handleCancel();
    if (isShow) {
      setStdId(std_id);
      setCleverId(clever_id);
    }
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [cleverId, stdId]);

  const checkDisabled = () => {
    if (cleverId && stdId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    const errors = validateCleverFields(stdId, cleverId);
    const isError = errors.stdId || errors.cleverId;
    if (isDisabled && !isError) {
      handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data: any = {
        id: stdId,
        clever_id: cleverId,
      };
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "studentId" && setStdId(value);
    name === "cleverId" && setCleverId(value);
    if (isSubmitError) {
      setClientErrors({ ...clientErrors });
    }
  };

  const clearData = () => {
    setStdId("");
    setCleverId("");
    setDisabled(true);
    setClientErrors({});
    setIsSubmitError(false);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Student</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <CustomInput
              fieldName="studentId"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["stdId"] !== undefined}
              value={stdId}
              autoFocus={true}
              type="text"
              label="Student ID"
              onChange={handleOnChange}
              error={clientErrors.stdId}
              errorMessage={clientErrors.stdId}
              overlayClick={() => setClientErrors({})}
            />
            <CustomInput
              fieldName="cleverId"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["cleverId"] !== undefined}
              value={cleverId}
              type="text"
              label="Clever ID"
              onChange={handleOnChange}
              error={clientErrors.cleverId}
              errorMessage={clientErrors.cleverId}
              overlayClick={() => setClientErrors({})}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface SchoolAdminUpdateProps {
  isShow: boolean;
  first_name: string;
  last_name: string;
  input_email: string;
  input_title: string;
  is_super_principal: string;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
  csv?: boolean;
}

export const SchoolAdminUpdateModal: React.FC<SchoolAdminUpdateProps> = ({
  isShow,
  loading,
  first_name,
  last_name,
  input_email,
  input_title,
  is_super_principal,
  onCancel,
  onUpdate,
  csv,
}) => {
  const [firstName, setFirstName] = useState(first_name);
  const [isSuperPrincipal, setIsSuperPrincipal] = useState(is_super_principal);
  const [lastName, setLastName] = useState(last_name);
  const [email, setEmail] = useState(input_email);
  const [title, setTitle] = useState(input_title);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { management } = useSelector((state: any) => state);

  const [isPrincipal, setPrincipal] = useState(
    is_super_principal === "y" || is_super_principal === "Y" ? true : false
  );
  console.log("+++++", is_super_principal);

  const dispatch = useDispatch();

  useEffect(() => {
    if (management.schoolAdminsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.schoolAdminsError,
      });
    }
  }, [management.schoolAdminsError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email, title, isPrincipal]);

  const checkDisabled = () => {
    if (
      firstName &&
      lastName &&
      email &&
      (firstName !== first_name ||
        lastName !== last_name ||
        email !== input_email ||
        input_title !== title ||
        isSuperPrincipal !== is_super_principal)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearData();
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data: any = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        title: title?.trim(),
      };
      if (csv) {
        data["is_super_principal"] = isPrincipal;
      }
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.UPDATE_SCHOOL_ADMIN_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const togglePrincipal = () => {
    setPrincipal(!isPrincipal);
    let oppositValue = !isPrincipal;
    setIsSuperPrincipal(oppositValue ? "Y" : "N");
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    return () => {
      document.removeEventListener("keydown", handlePress);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit School Admin</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              autoFocus={true}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="email"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["email"] !== undefined}
              value={email}
              type="text"
              label="Email"
              onChange={handleOnChange}
              error={clientErrors.email}
              errorMessage={clientErrors.email}
              overlayClick={() => {
                setClientErrors({
                  ...clientErrors,
                  email:
                    clientErrors.email === "Invalid Email"
                      ? "required"
                      : undefined,
                });
              }}
            />
            <Input
              fieldName="title"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["title"] !== undefined}
              value={title}
              type="text"
              label="Title"
              onChange={handleOnChange}
            />
            {csv && (
              <div className="modal__form-switch">
                <p>Is Principal</p>
                <label className="switch switch-small mb-0">
                  <input
                    type="checkbox"
                    onChange={togglePrincipal}
                    checked={isPrincipal}
                    className="switch-checkbox"
                  />
                  <span className="switch-slider switch-slider-small"></span>
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface CsvDistrictAdminUpdateProps {
  isShow: boolean;
  first_name: string;
  last_name: string;
  input_email: string;
  is_super_principal: string;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const CsvDistrictAdminUpdateModal: React.FC<
  CsvDistrictAdminUpdateProps
> = ({
  isShow,
  loading,
  first_name,
  last_name,
  input_email,
  is_super_principal,
  onCancel,
  onUpdate,
}) => {
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [email, setEmail] = useState(input_email);
  const [isPrincipal, setPrincipal] = useState(
    is_super_principal === "y" || is_super_principal === "Y" ? true : false
  );
  const [isSuperPrincipal, setIsSuperPrincipal] = useState(is_super_principal);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email, isPrincipal]);

  const checkDisabled = () => {
    if (
      firstName &&
      lastName &&
      email &&
      (first_name !== firstName ||
        lastName !== last_name ||
        email !== input_email ||
        isSuperPrincipal !== is_super_principal)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        admin_first_name: firstName.trim(),
        admin_last_name: lastName.trim(),
        email: email.trim(),
        is_super_principal: isPrincipal ? "Y" : "N",
        error: "",
      };
      onUpdate(data);
      handleCancel();
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setClientErrors({});
    setIsSubmitError(false);
    setPrincipal(false);
    setDisabled(true);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const togglePrincipal = () => {
    setPrincipal(!isPrincipal);
    let oppositValue = !isPrincipal;
    setIsSuperPrincipal(oppositValue ? "Y" : "N");
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    return () => {
      document.removeEventListener("keydown", handlePress);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit District Admin</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              autoFocus={true}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="email"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["email"] !== undefined}
              value={email}
              type="text"
              label="Email"
              onChange={handleOnChange}
              error={clientErrors.email}
              errorMessage="Invalid Email"
              overlayClick={() =>
                setClientErrors({
                  ...clientErrors,
                  email: "required",
                })
              }
            />
            <div className="modal__form-switch">
              <p>Is Superintendent</p>
              <label className="switch switch-small mb-0">
                <input
                  type="checkbox"
                  onChange={togglePrincipal}
                  checked={isPrincipal}
                  className="switch-checkbox"
                />
                <span className="switch-slider switch-slider-small"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface ClassAdminUpdateProps {
  isShow: boolean;
  first_name: string;
  last_name: string;
  input_email: string;
  input_title: string;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const ClassAdminUpdateModal: React.FC<ClassAdminUpdateProps> = ({
  isShow,
  loading,
  first_name,
  last_name,
  input_email,
  input_title,
  onCancel,
  onUpdate,
}) => {
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [email, setEmail] = useState(input_email);
  const [title, setTitle] = useState(input_title);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { management } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (management.classAdminsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.classAdminsError,
      });
    }
  }, [management.classAdminsError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email, title]);

  const checkDisabled = () => {
    if (
      firstName &&
      lastName &&
      email &&
      (firstName !== first_name ||
        lastName !== last_name ||
        email !== input_email ||
        input_title !== title)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearData();
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (isDisabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        title: title?.trim(),
      };
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.UPDATE_CLASSADMINS_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Class Admin</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              autoFocus={true}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="email"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["email"] !== undefined}
              value={email}
              type="text"
              label="Email"
              onChange={handleOnChange}
              error={clientErrors.email}
              errorMessage={clientErrors.email}
              overlayClick={() => {
                setClientErrors({
                  ...clientErrors,
                  email:
                    clientErrors.email === "Invalid Email"
                      ? "required"
                      : undefined,
                });
              }}
            />
            <Input
              fieldName="title"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["title"] !== undefined}
              value={title}
              type="text"
              label="Title"
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface CsvStudentUpdateProps {
  isShow: boolean;
  csvStudent: any;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const CsvStudentUpdateModal: React.FC<CsvStudentUpdateProps> = ({
  isShow,
  loading,
  csvStudent,
  onCancel,
  onUpdate,
}) => {
  const [firstName, setFirstName] = useState(csvStudent?.first_name);
  const [lastName, setLastName] = useState(csvStudent?.last_name);
  const [schoolStdId, setSchoolStdId] = useState(csvStudent?.student_id);
  const [notes, setNotes] = useState(csvStudent?.Notes);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, schoolStdId]);

  const checkDisabled = () => {
    if (firstName && lastName && schoolStdId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    const errors = validateStudentFields(firstName, lastName, schoolStdId);
    const isError = errors.firstName || errors.lastName || errors.schoolStdI;
    if (disabled && !isError) {
      return handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        Notes: notes,
        student_id: schoolStdId,
      };
      onUpdate(data);
      handleCancel();
    }
  };

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setSchoolStdId("");
    setNotes(true);
    setClientErrors({});
    setIsSubmitError(false);
    setDisabled(true);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "schoolStdId" && setSchoolStdId(value);
    name === "notes" && setNotes(value);
    if (isSubmitError) {
      const isValid =
        name === "schoolStdId" ? value.length === 10 : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Student</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              autoFocus={true}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="schoolStdId"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["schoolStdId"] !== undefined}
              value={schoolStdId}
              type="number"
              label="Student ID"
              onChange={handleOnChange}
              onKeyDownProp={(event: any) => checkIfNumber(event)}
              error={clientErrors.schoolStdId}
              errorMessage="Student ID must be 10 digits"
              overlayClick={() =>
                setClientErrors({
                  ...clientErrors,
                  schoolStdId: "required",
                })
              }
            />
            <Input
              fieldName="notes"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["notes"] !== undefined}
              value={notes}
              type="text"
              label="Notes"
              onChange={handleOnChange}
              error={clientErrors.notes}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || disabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface CleverIdUpdateProps {
  isShow: boolean;
  std_id: string;
  clever_id: string;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const CleverIdUpdateModal: React.FC<CleverIdUpdateProps> = ({
  isShow,
  loading,
  std_id,
  clever_id,
  onCancel,
  onUpdate,
}) => {
  const [stdId, setStdId] = useState("");
  const [cleverId, setCleverId] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);

  const { management } = useSelector((state: any) => state);

  const [checkCleverEmpty, setCheckCleverEmpty] = useState(true);

  useEffect(() => {
    !isShow && handleCancel();
    if (isShow) {
      setStdId(std_id);
      setCleverId(clever_id);
      if (clever_id?.length > 20 || clever_id?.length === 0) {
        setCheckCleverEmpty(false);
      } else {
        setCheckCleverEmpty(true);
      }
    }
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [cleverId, stdId]);

  const checkDisabled = () => {
    if (stdId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (management.studentUpdateError) {
      const errors = { cleverId: management.studentUpdateError };
      setClientErrors(errors);
      setIsSubmitError(true);
      management.studentUpdateError = null;
    }
  }, [management.studentUpdateError]);

  const onSubmit = () => {
    const errors = validateUpdateCleverFields(stdId, cleverId, true);
    const isError = errors.stdId || errors.cleverId;
    if (isDisabled && !isError) {
      handleCancel();
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data: any = {
        id: stdId,
        clever_id: cleverId,
      };
      onUpdate(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "studentId" && setStdId(value);
    name === "cleverId" && setCleverId(value);
    if (isSubmitError) {
      setClientErrors({ ...clientErrors });
    }

    if (name === "cleverId" && (value.length > 20 || value?.length === 0)) {
      setCheckCleverEmpty(false);
    } else if (name === "cleverId") {
      setCheckCleverEmpty(true);
    }
  };

  const clearData = () => {
    setStdId("");
    setCleverId("");
    setDisabled(true);
    setClientErrors({});
    setIsSubmitError(false);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Student Clever Id</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <CustomInput
              fieldName="cleverId"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["cleverId"] !== undefined}
              value={cleverId}
              type="text"
              label="Clever ID"
              onChange={handleOnChange}
              error={clientErrors.cleverId}
              errorMessage={clientErrors.cleverId}
              overlayClick={() => setClientErrors({})}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled || checkCleverEmpty) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};
