import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { Fragment, useContext, useEffect } from "react";
import Assessment from "./assessment";
import { SingleFormative } from "interfaces/professionalDevelopment";
import Filters from "./filters";

const InCompletedFormatives = ({
  updateSearch,
  onChangeFilter,
  formativeLoading,
  formatives,
  selectedFormative,
  setOpen,
  filter,
}: any) => {
  const {
    classroomId,
    isLoading,
    formatives: assessments,
  } = useContext(ProfessionalDevelopmentContext);
  const allMoved =
    assessments.length > 0
      ? assessments.every((item: SingleFormative) => item.is_completed)
      : false;

  useEffect(() => {
    setOpen(selectedFormative);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormative]);

  return (
    <>
      <div className="formative-assessment-sec">
        <div className="assessment-filter-header">
          <h2 className="secHeading">Lessons and Formative Assessments</h2>
          <a className="see-completed-btn" href="#completed-formative-sec">
            See Completed
          </a>
          <Filters
            updateSearch={updateSearch}
            onChangeFilter={onChangeFilter}
            filter={filter}
          />
        </div>
        {isLoading || formativeLoading ? (
          [0, 1, 2].map((id) => {
            return (
              <Fragment key={id}>
                <Assessment
                  formative={{}}
                  formativeLoading={formativeLoading}
                />
              </Fragment>
            );
          })
        ) : formatives?.length > 0 ? (
          formatives.map((formative: any) => {
            return (
              <Fragment key={formative.id}>
                {formative.moved ? (
                  <div className={`assessment-msg assessment-moved`}>
                    <p className="text-center mb-0">
                      This assessment has been moved to Completed Lessons and
                      Formative Assessments section.
                    </p>
                  </div>
                ) : (
                  <Assessment
                    formative={formative}
                    setOpen={() => setOpen(formative)}
                    open={false}
                    filter={filter}
                  />
                )}
              </Fragment>
            );
          })
        ) : (
          <>
            {allMoved ? (
              <div className={`assessment-msg assessment-moved`}>
                <p className="text-center mb-0">
                  Mark Completed off an assessment when all students have taken
                  the assessment and have been remediated.
                </p>
              </div>
            ) : (
              <table>
                <tbody>
                  <tr>
                    <td colSpan={10} className="notfound__data">
                      <div className="notfound__data-container">
                        {classroomId ? (
                          <p className="text-center text-shadow mb-0">
                            No Formative Found!
                          </p>
                        ) : (
                          <p className="text-center text-shadow mb-0">
                            Select a classroom above to view Formative
                            Assessments.
                          </p>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InCompletedFormatives;
